
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import productService from '@/services/product-service';
import projectService from "@/services/project-service";
import purchaseService from '@/services/purchase-services'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";
@Component({
  components: {
    Header,
    SidebarPurchase,
    Loader,
    PopUp
  },
})
export default class PurchaseEnquires extends Vue {
  loading = true;
  public document_file: any = null;
  public payload: any = {}
  transaction_options: any = {}
  trans: any = {}
  product: any = {};
  items: any = [{ items_master: {}, desc: '', qty: 0, unit: '', brand: '', amount: '', project: {}, item_type: '',comment: '' }];
  other_info: any = {}
  product_opt: any = []
  selected_projects: any = []
  selected_employees: any = []
  selected_currency: any = [];
  currency_opt: any = []
  itemsOptions: any = [
    { value: null, text: 'Please select Items Type' },
    { value: 'Assets', text: 'Assets' },
    { value: 'Inventory', text: 'Inventory' },
    { value: 'Consumable', text: 'Consumable' },
    { value: 'Third Party', text: 'Third Party' },
  ]
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  mounted() {
    this.retrieve()
  }
  retrieve() {
    this.loading = true;
    purchaseService.getAllCurencies().then((resp) => {
      this.currency_opt = resp.data
      this.loading = false;
    }).catch((e) => {
      console.log(e);
    });
  }
  getRate(data: any) {
    this.payload['transaction_rate'] = data.rate
    this.payload['transaction_id'] = data.id
  }
  addRow() {
    this.items.push({ items_master: {}, desc: '', qty: 0, unit: '', brand: '', amount: '', project: {}, item_type:'' ,comment: '' })
  }
  deleteRow(index: any) {
    this.items.splice(index, 1);
  }
  clearRow(index: any) {
    const item = this.items[index];
    item.items_master.sku = ""
    item.items_master.title = ""
    item.desc = ''
    item.qty = 0
    item.items_master.no_of_pieces = ''
    item.brand = ''
    item.amount = ''
    item.project = {}
    item.item_type=''
    item.comment = ''
  }
  save() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.payload, ['request_date', 'transaction', 'transaction_rate', 'cost_center_title'])
    const items_error_list = utils.validateArray(this.items, ['items_master', 'qty', 'desc', 'brand','item_type'])
    const combinedErrors = [...error_list, ...items_error_list];
    if (combinedErrors.length > 0 || this.document_file === null) {
      this.error = utils.generateErrorMessage(combinedErrors.concat(this.document_file === null ? 'File' : ''))
    }
    else {
      this.payload['items'] = this.items
      this.payload['other_info'] = this.other_info
      purchaseService.createEnquiryRequest(this.payload).then((resp) => {
        if (resp.status === 201) {
          purchaseService.uploadEnquiryDoc(this.document_file, resp.data.result.id).then((response) => {
            this.showModal("Succesfully created", [], "success");
            this.reset();
          })
        }
      }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }
  index = -1
  search_product_item(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      productService
        .getByTitle(title)
        .then((response) => {
          this.product_opt = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.product_opt = [];
    }
  }
  get_product_item(data: any) {
    this.items[this.index].items_master = data;
    this.product_opt = [];
    this.index = -1
  }
  emp_index = 0;
  search_employee(title: any, index: any) {
    this.emp_index = index;
    if (title.length > 2) {
      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.selected_employees = [];
    }
  }
  get_data(data: any) {
    this.payload.executive = data.id;
    this.payload.executive_title = data.title;

    this.selected_employees = [];
  }
  type = ""
  project_index = 0
  searchProject(title: any, index: any, type: any) {
    this.type = type
    this.project_index = index
    if (title.length == 0) {
      this.selected_projects = [];
      return
    }
    projectService.getByTitle(title).then((response) => {
      this.selected_projects = response.data.result
    })

  }
  get_project_data(data: any, type: any) {
    if (type == 'project') {
      this.items[this.project_index].project = data
    }
    else if (type == 'cost-center') {
      this.payload.cost_center_title = data.title
      this.payload.cost_center = data.id
    }
    this.selected_projects = [];
  }
  reset() {
    this.payload = {};
    this.error = "";
    this.other_info={};
    this.document_file = null;
    this.clearRow(0);
    for (let i = this.items.length - 1; i > 0; i--) {
      this.deleteRow(i);
    }
  }
}
